import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit {

  @Input() label: string;
  @Input() back_button_icon: string;
  @Input() icon_label: string;
  @Input() button_right: string;
  @Output() EventoButtonRight = new EventEmitter();

  constructor(public navController: NavController) { }

  ngOnInit() { }
}
