import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Cliente, ClienteResponse } from '../models/cliente.model';
import { HttpCallService } from './http-call.service';
import { StorageService } from './storage.service';

@Injectable()
export class ClienteService {
    baseURL = environment.apiBase;

    constructor(
        private httpCallService: HttpCallService,
        private storageService: StorageService
    ) { }

    async getClientsBySearch(query?: { search?: string, last_name?: string }): Promise<Cliente[]> {
        let url = `${this.baseURL}/customers`;
        if (!(Object.keys(query).length === 0)) {
            url = url + '?';
            if (query['search']) url += `q[search]=${query['search']}&`;
            if (query['last_name']) url += `q[last_name]=${query['last_name']}&`;
            if (query) url = url.slice(0, -1);
        }
        return null
        // return this.http.get<Cliente[]>(url).toPromise();
    }

    async getClients(query?: { search?: string, last_name?: string }): Promise<Cliente[]> {
        let clientes_storage = await this.storageService.getClients();
        if (clientes_storage) return Promise.resolve(clientes_storage);
        // else { // PARA PROBAR
        //     await this.storageService.setClients(clientes);
        //     return await this.storageService.getClients();
        // }
    }

    postClientData(client_body: Cliente) {
        return this.storageService.addClient(client_body)
    }

    deleteClient(id_cliente: number) {
        return null
    }

    async putClientData(client_body: Cliente): Promise<Cliente> {
        return await this.storageService.updClient(client_body)
    }

    async syncPostClients(client): Promise<any> {
        return this.httpCallService.call(`${this.baseURL}/prospect_customers`, client, 'post', 'Sincronizando cliente...');
    }

    async syncPutClients( client ): Promise<any> {
        let url = `${this.baseURL}/prospect_customers/${client.prospect_customer.id_back}`;
        return this.httpCallService.call( url, client, 'put', 'Sincronizando cliente...');
    }
}