import { Injectable } from '@angular/core';
import { AngularTokenService } from 'angular-token';
import { StorageService } from './storage.service';
import { LoginDataAuthCrm } from '../models/user.model';
import { NavController, LoadingController } from '@ionic/angular';

@Injectable()
export class UserService {

    constructor(
        private navController: NavController,
        private apiTokenService: AngularTokenService,
        private storageService: StorageService,
        private loadingController: LoadingController
    ) {
    }

    async login(login_form: { login: string, password: string }): Promise<any> {
        let loading = await this.loadingController.create({
            message: "Ingresando.."
        });
        loading.present()
        return this.apiTokenService.signIn(login_form).toPromise().then(async (data) => {
            loading.dismiss();
            const loginData: LoginDataAuthCrm = data.body;
            await this.storageService.setUserSessionAuth(loginData); // debería hacerlo el signIn pero no lo está haciendo.
            return Promise.resolve({ success: true, data: loginData });
        }, (e: Error) => {
            loading.dismiss();
            console.log(e.message);
            return Promise.reject('Error de autenticación');
        });
    }

    async logOut() {
        // const { success } = await this.apiTokenService.signOut().toPromise();
        // if (success) {
        await this.storageService.logOut();
        this.navController.navigateRoot("/login");
        // }
    }

    async getAuthData(): Promise<LoginDataAuthCrm> {
        const authData: LoginDataAuthCrm = await this.storageService.getUserSessionAuth();
        return Promise.resolve(authData);
    }

}