import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Cliente } from '../models/cliente.model';
import { LoginData, LoginDataAuthCrm } from '../models/user.model';

@Injectable({
    providedIn: 'root'
})

export class StorageService {
    private readonly USSER_SESSION = 'crmClienteUser';
    private readonly USSER_SESSION_AUTH = 'crmClienteUserLoginAuth';
    private readonly SELECTED_CLIENT = 'client_crm';
    private readonly CLIENTS = 'clients_crm';

    constructor(private storage: Storage) { }

    // USSER_SESSION - crmRedmasAuthData20
    public getUserSession(): Promise<LoginData> { return this.storage.get(this.USSER_SESSION) }
    public setUserSession(user_session: LoginData): Promise<void> { return this.storage.set(this.USSER_SESSION, user_session) }
    public removeUserSession(): Promise<void> { return this.storage.remove(this.USSER_SESSION) }

    // USSER_SESSION_AUTH
    public getUserSessionAuth(): Promise<LoginDataAuthCrm> { return this.storage.get(this.USSER_SESSION_AUTH) }
    public setUserSessionAuth(login_user_data: LoginDataAuthCrm): Promise<void> { return this.storage.set(this.USSER_SESSION_AUTH, login_user_data) }
    public removeUserSessionAuth(): Promise<void> { return this.storage.remove(this.USSER_SESSION_AUTH) }

    // SELECTED_CLIENT - client_crm
    public setClientSelected(client: Cliente): Promise<void> { return this.storage.set(this.SELECTED_CLIENT, client) }
    public getClientSelected(): Promise<Cliente> { return this.storage.get(this.SELECTED_CLIENT) }
    public removeClient(): Promise<undefined> { return this.storage.remove(this.SELECTED_CLIENT) }

    // CLIENTS - client_crm
    public setClients(client: Cliente[]): Promise<void> { return this.storage.set(this.CLIENTS, client) }
    public addClient(client: Cliente): Promise<void> {
        this.getClients().then(clientes => {
            if (clientes && clientes[0]) client['Id'] = clientes[0].Id + 1
            else {
                clientes = [];
                client['Id'] = 1;
            }
            clientes.unshift(client);
            return this.setClients(clientes);
        });
        return Promise.resolve();
    }
    public getClients(): Promise<Cliente[]> { return this.storage.get(this.CLIENTS) }
    public updClient(client: Cliente): Promise<undefined> {
        this.getClients().then(clientes => {
            let index: number = clientes.findIndex(resp => resp.Id == client.Id);
            if (index == -1) return;
            clientes[index] = client;
            if (client.Sincronizado) clientes[index].edited = true;
            return this.setClients(clientes);
        })
        return null
    }

    public removeClients(): Promise<undefined> { return this.storage.remove(this.CLIENTS) }

    // REPORTED_CLIENTS - [remove in storage]
    async removeStoredClientByHoursPeriod(nro_hs: number): Promise<Cliente[]> {
        const limit: number = nro_hs * 600000; // cant_hs * ms
        const reported_clients: Cliente[] = await this.getClients();
        if (!reported_clients) return;
        const unreported_clients: Cliente[] = reported_clients.filter((cliente: Cliente) => {
            return !cliente.Sincronizado || (new Date().getTime() - (new Date(cliente.Sincronizado)).getTime()) < limit;
        });
        await this.setClients(unreported_clients);
        let clients: Cliente[] = await this.getClients();
        return clients;
    }

    public logOut() {
        return Promise.all([
            this.removeUserSession(),
            this.removeUserSessionAuth()
        ])
    }
}
