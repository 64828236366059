import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class HttpCallService {
  url_base = ' environment.apiBase';
  public loading: HTMLIonLoadingElement;

  constructor(
    private http: HttpClient,
    public loadingController: LoadingController
  ) { }

  public async call(url: string, body: any, http_call: string, sms_loading: string): Promise<any> {
    let loading = await this.loadingController.create({
      message: sms_loading
    });
    await loading.present();
    let call: Observable<any>;
    switch (http_call) {
      case 'get':
        call = this.http.get(url);
        break;
      case 'post':
        call = this.http.post(url, body);
        break;
      case 'put':
        call = this.http.put(url, body);
        break;
      case 'delete':
        call = this.http.delete(url);
        break;
      default:
        break;
    }
    return call.toPromise().then((response) => {
      loading.dismiss();
      return Promise.resolve(response);
    }).catch((e: Error) => {
      loading.dismiss();
      return Promise.reject(e.message);
    });
  }
}